import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Section } from "../../UI/Common"
import { styledButton } from './VideoCallToAction.module.scss'
import ModalTriggerButton from "../../UI/ModalTriggerButton"

const VideoCallToAction = ({ title, paragraph,videoButtonText, config }) => {  
  const { padding } = config || {}
  return (
    <Section padding={padding}>
      <Container className={`p-5`} style={{background: '#F9F9F9'}} >
        <Row>
          <Col className="d-flex pl-lg-5 flex-column align-items-center justify-content-center">
            <h2 className="h3 text-lg-left w-100">{title}</h2>
            {paragraph && <p className="m-0">{paragraph}</p>}
          </Col>
          <Col className="d-flex align-items-center justify-content-center pt-4 pt-md-0">          
          <ModalTriggerButton buttonText={videoButtonText} type="video" className={styledButton}/> 
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default VideoCallToAction
