// extracted by mini-css-extract-plugin
export var bgGrey100 = "VideoCallToAction-module--bg-grey-100--7e79e";
export var bgGrey150 = "VideoCallToAction-module--bg-grey-150--e9366";
export var bgGrey200 = "VideoCallToAction-module--bg-grey-200--5c7df";
export var bgGrey300 = "VideoCallToAction-module--bg-grey-300--7ea31";
export var bgGrey400 = "VideoCallToAction-module--bg-grey-400--ce741";
export var bgGrey500 = "VideoCallToAction-module--bg-grey-500--76829";
export var bgGrey600 = "VideoCallToAction-module--bg-grey-600--1ae02";
export var bgGrey700 = "VideoCallToAction-module--bg-grey-700--b8c8b";
export var bgGrey800 = "VideoCallToAction-module--bg-grey-800--48ac8";
export var bgGrey900 = "VideoCallToAction-module--bg-grey-900--81e61";
export var styledButton = "VideoCallToAction-module--styledButton--ca356";
export var textGrey100 = "VideoCallToAction-module--text-grey-100--6c8d9";
export var textGrey150 = "VideoCallToAction-module--text-grey-150--d4b53";
export var textGrey200 = "VideoCallToAction-module--text-grey-200--91ca3";
export var textGrey300 = "VideoCallToAction-module--text-grey-300--b7ca4";
export var textGrey400 = "VideoCallToAction-module--text-grey-400--72114";
export var textGrey500 = "VideoCallToAction-module--text-grey-500--79844";
export var textGrey600 = "VideoCallToAction-module--text-grey-600--803fc";
export var textGrey700 = "VideoCallToAction-module--text-grey-700--fcf66";
export var textGrey800 = "VideoCallToAction-module--text-grey-800--97dcf";
export var textGrey900 = "VideoCallToAction-module--text-grey-900--d7330";