import { graphql } from "gatsby"
import React from "react"
import VideoCallToAction from "./VideoCallToAction"

export const fragment = graphql`
  fragment VideoCallToActionFragment on WpPage_Flexlayouts_FlexibleLayouts_VideoCallToAction {
    title
    paragraph
    videoButtonText
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`
export const ACFVideoCallToAction = ({ title, paragraph, videoButtonText, config }) => {  
  return (
    <VideoCallToAction
      title={title}
      paragraph={paragraph}
      videoButtonText={videoButtonText}
      config={config}
    />    
  )
}
